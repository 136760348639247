import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import { decode } from '@vl/mod-utils/jwt';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),

            userInfo: hook((ctx) => {
              const user_id = ctx.apply('authModel.getTargetProfileId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const condition = {
                where: {
                  user_id: { _eq: user_id },
                  account_id: { _eq: account_id },
                },
              };
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!user_id && !account_id) return null;
                    const client = await getClient();
                    const user_data = await client.request(
                      gql`
                        query GetInformation($where: b2b_member_profile_bool_exp = {}) {
                          b2b_member_profile(where: $where) {
                            first_name
                            last_name
                            phone
                            email
                            date_of_birth
                          }
                        }
                      `,
                      { ...condition }
                    );
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    return user_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [user_id, account_id]
              );

              const userInfo = _.get(data, 'b2b_member_profile.0', {});
              return userInfo;
            }),

            currentLanguage: hook((ctx) => {
              const route = useRoute();
              const pageContext = route.getPageContext();
              const lang = _.get(pageContext, 'lang', 'de');
              return lang;
            }),

            form: hook((ctx) => {
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  first_name: ctx.get('userInfo.first_name'),
                  last_name: ctx.get('userInfo.last_name'),
                  date_of_birth: moment(ctx.get('userInfo.date_of_birth')),
                  email: ctx.get('userInfo.email'),
                  phone: ctx.get('userInfo.phone'),
                  time_zone: '',
                  language: '',
                  region: '',
                  calendar: '',
                  font_size: '',
                  current_password: '',
                  new_password: '',
                  confirm_password: '',
                },

                onSubmit: async (values, actions) => {
                  try {
                    console.log(values);
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                selectOptions: {
                  language: (() => {
                    return _.keys(ctx.get('i18n.translations')).map((item, index) => {
                      return {
                        id: index,
                        text: ctx.apply('i18n.t', `SettingLanguage.${item}`),
                        value: item,
                      };
                    });
                  })(),
                  region: (() => {
                    return [
                      {
                        id: 'vietnam',
                        text: 'Viet Nam',
                      },
                      {
                        id: 'english',
                        text: 'English',
                      },
                      {
                        id: 'germany',
                        text: 'Germany',
                      },
                    ];
                  })(),
                  calendar: (() => {
                    return [
                      {
                        id: 'gregorian',
                        text: 'Gregorian',
                      },
                      {
                        id: 'japanese',
                        text: 'Japanese',
                      },
                      {
                        id: 'buddhist',
                        text: 'Buddhist',
                      },
                    ];
                  })(),
                  timezone: (() => {
                    return [
                      {
                        id: 'vietnam',
                        text: '(UTC+07:00) Bangkok, Hanoi, Jakarta)',
                      },
                      {
                        id: 'new-zealand',
                        text: '(UTC+12:00) Wellington, New Zealand',
                      },
                      {
                        id: 'germany',
                        text: '(UTC+01:00) Berlin, Germany',
                      },
                      {
                        id: 'portugal',
                        text: '(UTC+00:00) Lisbon, Portugal',
                      },
                    ];
                  })(),
                  font_size: (() => {
                    return [
                      {
                        id: '1',
                        text: '16',
                      },
                      {
                        id: '2',
                        text: '20',
                      },
                      {
                        id: '3',
                        text: '24',
                      },
                    ];
                  })(),
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.apply('loadingModel.isLoading');
          }),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
