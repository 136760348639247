import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { FormItem, DatePicker } from 'formik-antd';
import InputText from '@uz/unitz-components-web/InputText';
import { Row, Col } from 'antd';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import moment from 'moment';
import i18n from 'i18n-js';
import { START_DATE_YEAR_ML_FORMAT } from '@vl/mod-utils/datetime';
import { datePickerLocaleMapping } from '@vl/mod-utils/datePickerLocaleMapping';

const View8 = () => {
  const locale = i18n.locale;

  const dateFormatter = (value) => {
    return `${moment(value).format(_.get(START_DATE_YEAR_ML_FORMAT, locale, 'de'))}`;
  };

  return (
    <DIV className="component">
      <div className="bg-white500 shadow-md rounded-lg">
        <div className="p-6">
          <div className="text-main font-semibold text-base">
            {ctx.apply('i18n.t', 'ZoomToolMyAccount.edit_information')}
          </div>
        </div>
        <div className="border-b" />
        <div className="flex flex-col space-y-4 p-6 bg-white500 rounded-lg">
          <CustomInput>
            <FormItem
              name="first_name"
              label={
                <div className="text-sub text-sm font-semibold">
                  {ctx.apply('i18n.t', 'ZoomToolMyAccount.first_name_label')}
                </div>
              }
            >
              <InputText name="first_name" />
            </FormItem>
          </CustomInput>

          <CustomInput>
            <FormItem
              name="last_name"
              label={
                <div className="text-sub text-sm font-semibold">
                  {ctx.apply('i18n.t', 'ZoomToolMyAccount.last_name_label')}
                </div>
              }
            >
              <InputText name="last_name" />
            </FormItem>
          </CustomInput>
          <Row>
            <Col span={24}>
              <CustomInput>
                <FormItem
                  name="date_of_birth"
                  label={
                    <div className="text-sub text-sm font-semibold">
                      {ctx.apply('i18n.t', 'ZoomToolMyAccount.dob_label')}
                    </div>
                  }
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    name="date_of_birth"
                    allowClear={false}
                    locale={datePickerLocaleMapping(locale)}
                    format={dateFormatter}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                </FormItem>
              </CustomInput>
            </Col>
          </Row>

          <CustomInput>
            <FormItem
              name="email"
              label={<div className="text-sub text-sm font-semibold">{ctx.apply('i18n.t', 'ProfileUser.email')}</div>}
            >
              <InputText name="email" disabled />
            </FormItem>
          </CustomInput>

          <CustomInput>
            <FormItem
              name="phone"
              label={
                <div className="text-sub text-sm font-semibold">{ctx.apply('i18n.t', 'ProfileUser.phoneNumber')}</div>
              }
            >
              <InputText name="phone" />
            </FormItem>
          </CustomInput>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
