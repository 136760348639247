import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import Button from '@uz/unitz-components-web/Button';
import ToolInformationSetting from '@uz/unitz-tool-components/ToolInformationSetting';
import ToolPasswordSetting from '@uz/unitz-tool-components/ToolPasswordSetting';
import ToolFontsizeSetting from '@uz/unitz-tool-components/ToolFontsizeSetting';
import ToolSelectSetting from '@uz/unitz-tool-components/ToolSelectSetting';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';

const layouts = {
  labelCol: { span: 6 },
  labelAlign: 'left',
};

const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 h-full">
          <DIV className="isLoading">
            <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
              <LoadingScreen />
            </div>
          </DIV>
          <DIV className="notLoading">
            <FormProvider form={ctx.get('form')}>
              <Form {...layouts} className="my-account-form">
                <div className="flex flex-col space-y-4">
                  <ToolInformationSetting />
                  <ToolPasswordSetting />
                  <ToolSelectSetting
                    name="time_zone"
                    title={ctx.apply('i18n.t', 'ZoomToolMyAccount.timezone')}
                    note={ctx.apply('i18n.t', 'ZoomToolMyAccount.timezone_note')}
                    label={ctx.apply('i18n.t', 'ZoomToolMyAccount.timezone')}
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMyAccount.time_zone_placeholder')}
                    data={ctx.get('form.selectOptions.timezone')}
                  />
                  <ToolSelectSetting
                    name="language"
                    title={ctx.apply('i18n.t', 'Setting.Language.yourLanguage')}
                    note={ctx.apply('i18n.t', 'ZoomToolMyAccount.language_note')}
                    label={ctx.apply('i18n.t', 'Setting.Language.language')}
                    placeholder={ctx.apply('i18n.t', `SettingLanguage.${ctx.get('currentLanguage')}`)}
                    data={ctx.get('form.selectOptions.language')}
                  />

                  <ToolSelectSetting
                    name="region"
                    title={ctx.apply('i18n.t', 'ZoomToolMyAccount.region')}
                    note={ctx.apply('i18n.t', 'ZoomToolMyAccount.region_note')}
                    label={ctx.apply('i18n.t', 'ZoomToolMyAccount.region')}
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMyAccount.region_placeholder')}
                    data={ctx.get('form.selectOptions.region')}
                  />

                  <ToolSelectSetting
                    name="calendar"
                    title={ctx.apply('i18n.t', 'ZoomToolMyAccount.calendar')}
                    note={ctx.apply('i18n.t', 'ZoomToolMyAccount.calendar_note')}
                    label={ctx.apply('i18n.t', 'ZoomToolMyAccount.calendar')}
                    placeholder={ctx.apply('i18n.t', 'ZoomToolMyAccount.calendar_placeholder')}
                    data={ctx.get('form.selectOptions.calendar')}
                  />

                  <ToolFontsizeSetting />
                  <div className="p-6 rounded-lg bg-white500 shadow-lg">
                    <Row justify="end">
                      <Col span={4}>
                        <Button
                          size="medium"
                          type="primary"
                          loading={ctx.get('form.isSubmitting')}
                          onClick={() => ctx.apply('form.submitForm')}
                          disabled={!ctx.get('canSubmit')}
                          block
                        >
                          {ctx.apply('i18n.t', 'Setting.Language.save')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>
            </FormProvider>
          </DIV>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
